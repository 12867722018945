import React, { useState } from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";
import { useFlexSearch } from "react-use-flexsearch";
import { Formik, Form, Field } from "formik";

const CloseButton = styled.button`
  border: none;
  border-radius: var(--border-small);
  background: transparent;
  color: var(--black);
  padding: 1.8rem 2rem 0px;
  font-family: var(--font-oswald-bold);
  text-transform: uppercase;
  font-size: 1.8rem;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  line-height: 1;
  transition: color 0.4s ease;

  span {
    display: flex;
    font-size: 2rem;
    line-height: 0;
    margin-top: 0.2rem;
    margin-right: 0.6rem;
  }

  &:hover {
    color: var(--red-light);
  }
`;

const SearchWrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchContent = styled.div`
  background-color: var(--white);
  border-radius: var(--border-regular);
  position: relative;
  width: 98vw;
  height: 98vh;
  max-width: var(--full-max-width);
  padding-top: 4.8rem;
  padding-bottom: 2rem;
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  overflow: hidden;
`;

const SearchHeader = styled.h4`
  font-size: 1.6rem;
  margin-bottom: 1rem;
  font-family: var(--font-oswald-regular);
  text-transform: uppercase;
`;

const InputStyle = styled.div`
  border: 1px solid var(--black);
  padding: 0.5rem;
  display: flex;
  border-radius: var(--border-small);
  flex-wrap: wrap;

  input {
    border: none;
    font-family: var(--font-oswald-regular);
    padding: 0.5rem 1rem;
    font-size: 1.6rem;
    flex: 1;
    line-height: 1;
    margin-bottom: 1rem;
    color: ${(props) =>
      props?.inputSubmittedStyled ? "var(--grey-light)" : "var(--black)"};
    pointer-events: ${(props) =>
      props?.inputSubmittedStyled ? "none" : "default"};

    @media (min-width: 768px) {
      font-size: 2.1rem;
      margin-bottom: 0px;
    }
  }
`;

const SearchButton = styled.button`
  margin-left: 1rem;
  border: 1px solid var(--black);
  background-color: var(--black);
  color: var(--white);
  border: none;
  font-family: var(--font-oswald-regular);
  text-transform: uppercase;
  padding-left: 2rem;
  padding-right: 2rem;
  cursor: pointer;
  @media (min-width: 768px) {
    height: 100%;
  }

  &:disabled {
    border: 1px solid var(--grey-light);
    background-color: var(--grey-light);
    color: var(--grey-dark);
    cursor: default;
  }
`;

const ClearButton = styled.button`
  margin-left: 1rem;
  background-color: var(--white);
  color: var(--black);
  border: 1px solid var(--black);
  font-family: var(--font-oswald-regular);
  text-transform: uppercase;
  padding-left: 2rem;
  padding-right: 2rem;
  cursor: pointer;
  @media (min-width: 768px) {
    height: 100%;
  }

  &:disabled {
    border-color: var(--grey-light);
    color: var(--grey-dark);
    cursor: default;
  }
`;

const NoResultWrapper = styled.div`
  padding: 4rem 2rem;
  text-align: center;

  h4 {
    margin-bottom: 1rem;
  }

  a {
    color: var(--red-light);
  }
`;

const ResultList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0px;
  height: 80%;
  overflow-x: auto;
  padding-bottom: 2rem;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    align-items: stretch;
  }

  @media (min-width: 1366px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  > li {
    margin-bottom: 0.8rem;

    @media (min-width: 768px) {
      margin-bottom: 0px;
    }

    a {
      border: 2px solid var(--grey-dark);
      padding: 1rem;
      border-radius: var(--border-small);
      font-size: 1.4rem;
      color: var(--black);
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      height: 100%;
      transition: border-color 0.25s ease;

      @media (min-width: 1366px) {
        &:not(:hover) {
          opacity: 0.35;
        }
        &:hover {
          border-color: var(--black);
        }
      }

      ul {
        list-style: none;
        padding: 0px;
        display: flex;
        margin-top: 0.4rem;
        font-size: 1.2rem;
        flex-wrap: warp;

        li {
          margin-left: 0.4rem;

          &:first-child {
            margin-left: 0px;
          }
        }
      }
    }
  }
`;

export default function SearchProduct({ onClose = null }) {
  const data = useStaticQuery(graphql`
    query {
      localSearchProductPages {
        index
        store
      }
    }
  `);

  const { index, store } = data.localSearchProductPages;

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [query, setQuery] = useState(null);
  const results = useFlexSearch(query, index, store);

  return (
    <SearchWrapper>
      <SearchContent>
        <CloseButton onClick={() => onClose?.()}>Close</CloseButton>
        <SearchHeader>
          {query ? `Search for: ${query}` : "search our products"}
        </SearchHeader>
        <Formik
          initialValues={{ query: "" }}
          onSubmit={(values, { setSubmitting }) => {
            setQuery(values.query);
            setSubmitting(false);
            setIsSubmitted(true);
          }}
        >
          {({ values, isSubmitting, resetForm }) => (
            <Form>
              <InputStyle inputSubmittedStyled={isSubmitted}>
                <Field
                  name='query'
                  placeholder='please type here and click search button to search our products'
                />
                <span>
                  <SearchButton
                    type='submit'
                    disabled={
                      isSubmitting || values.query.length === 0 || isSubmitted
                    }
                  >
                    search
                  </SearchButton>
                  <ClearButton
                    disabled={!isSubmitted}
                    type='button'
                    onClick={() => {
                      setQuery(null);
                      setIsSubmitted(false);
                      resetForm();
                    }}
                  >
                    new search
                  </ClearButton>
                </span>
              </InputStyle>
            </Form>
          )}
        </Formik>
        {results?.length === 0 && query?.length > 0 ? (
          <NoResultWrapper>
            <h4>No result, please click new search button above or</h4>
            <Link
              to='/our-products'
              activeClassName='active'
              onClick={() => onClose?.()}
            >
              view all products
            </Link>
          </NoResultWrapper>
        ) : (
          <ResultList>
            {results.map((result) => (
              <li key={result.id}>
                <Link
                  to={`/product/${result.path}`}
                  onClick={() => onClose?.()}
                >
                  <h5>
                    {result.name} {result.chinese_name}
                  </h5>
                  <ul>
                    <li>{result.brand} -</li>
                    <li>{result.code}</li>
                  </ul>
                </Link>
              </li>
            ))}
            {results.length > 0 && (
              <li>
                <Link
                  to='/our-products'
                  activeClassName='active'
                  onClick={() => onClose?.()}
                >
                  <h5>view all products</h5>
                </Link>
              </li>
            )}
          </ResultList>
        )}
      </SearchContent>
    </SearchWrapper>
  );
}
