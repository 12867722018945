import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    --red-light: #e01e2f;
    --red-dark: #b21016;
    --black: #150224;
    --yellow-light: #ffe0b2;
    --yellow-dark: #fbd091;
    --yellow-link-light: #f6d25e;
    --white: #ffffff;
    --grey-light: #e8e6e9;
    --grey-dark: #f1f0f0;
    --border-small: 2px;
    --border-regular: 4px;
    --border-large: 8px;
    --width-percentage: 95%;
    --max-width: 1120px;
    --full-max-width: 1240px;
    --font-oswald-regular: OswaldRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --font-oswald-bold: OswaldBold, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --font-quattrocento-regular: QuattroCentoRegular, serif;
    --font-quattrocento-bold: QuattroCentoBold, serif;
    --font-chinese: "細明體", Arial, Helvetica, sans-serif;
  }

  *, *:before, *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }

  html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 10px;
  }

  body {
    font-size: 2rem;
  }

  ::selection {
    background-color: rgba(255, 225, 185, 0.35);
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  .a11y-hide {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  img {
    max-width: 100%;
  }

  .red-button {
    align-items: center;
    display: flex;
    background-color: var(--red-dark);
    color: var(--white);
    justify-content: center;
    padding-left: 2.8rem;
    padding-right: 2.8rem;
    font-family: var(--font-oswald-regular);
    font-size: 1.6rem;
    text-transform: uppercase;
    height: 4rem;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 2rem;
    transition: background-color 0.25s ease, color 0.25s ease;

    @media (min-width: 768px) {
      margin: 0px;

      &:hover {
        background-color: var(--black);
        color: var(--yellow-light);
      }
    }
  }

  .black-button {
    align-items: center;
    display: flex;
    background-color: var(--black);
    color: var(--white);
    justify-content: center;
    padding-left: 2.8rem;
    padding-right: 2.8rem;
    font-family: var(--font-oswald-regular);
    font-size: 1.6rem;
    text-transform: uppercase;
    height: 4rem;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 2rem;
    transition: background-color 0.25s ease;

    @media (min-width: 768px) {
      margin: 0px;

      &:hover {
        background-color: var(--red-dark);
      }
    }
  }
`;

export default GlobalStyles;
