import React from "react";
import styled from "styled-components";

const FooterWrapper = styled.footer`
  bottom: 1rem;
  color: var(--white);
  font-family: var(--font-oswald-regular);
  left: 0px;
  right: 0px;
  text-transform: uppercase;
  margin: auto;
  position: fixed;
  width: var(--width-percentage);

  @media (min-width: 768px) {
    background-color: var(--red-dark);
    border-radius: var(--border-regular);
    display: flex;
    overflow: hidden;
    transition: background-color 0.25s ease;
    justify-content: space-between;
    max-width: var(--max-width);

    &:hover {
      background-color: var(--black);
    }
  }
`;

const FooterLeftStyles = styled.div`
  background-color: var(--red-dark);
  border-top-left-radius: var(--border-regular);
  border-top-right-radius: var(--border-regular);
  margin-bottom: 0.1rem;
  padding: 1rem;
  display: flex;
  align-items: center;

  justify-content: center;

  @media (min-width: 768px) {
    margin-bottom: 0px;
    padding: 1.4rem;
    background-color: transparent;
  }

  p {
    font-size: 1.2rem;
    margin: 0px;
  }
`;

const FooterRightStyles = styled.div`
  background-color: var(--red-dark);
  border-bottom-left-radius: var(--border-regular);
  border-bottom-right-radius: var(--border-regular);
  font-size: 1rem;
  padding: 1rem;
  text-align: center;

  @media (min-width: 768px) {
    padding: 1.4rem;
    font-size: 1.4rem;
    background-color: transparent;
  }

  p {
    font-size: 1.2rem;
    margin: 0px;
  }
`;

export default function Footer() {
  return (
    <FooterWrapper>
      <FooterLeftStyles>
        <p>&copy; copyright {new Date().getFullYear()}</p>
      </FooterLeftStyles>
      <FooterRightStyles>
        <p>all rights reserved shuey shing pty ltd</p>
      </FooterRightStyles>
    </FooterWrapper>
  );
}
