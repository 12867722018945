import React, { useState, useEffect } from "react";
import { useStaticQuery, Link, graphql } from "gatsby";
import useMediaQuery from "use-media-query-hook";
import styled from "styled-components";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import { GatsbyImage } from "gatsby-plugin-image";
import SearchProduct from "./SearchProduct";

const HeaderWrapper = styled.header`
  display: flex;
  font-family: OswaldRegular;
  justify-content: space-between;
  left: 0px;
  margin: auto;
  position: fixed;
  right: 0px;
  top: 0px;
  width: var(--width-percentage);
  z-index: 100;

  @media (min-width: 768px) {
    max-width: var(--max-width);
  }
`;

const LogoWrapper = styled.div`
  background-color: var(--white);
  border-bottom-left-radius: var(--border-small);
  border-bottom-right-radius: var(--border-small);
  height: 10rem;
  padding-bottom: 1rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 1.4rem;
  width: 9rem;
`;

const MobileMenuButton = styled.button`
  align-items: center;
  border: none;
  background-color: var(--red-dark);
  border-radius: var(--border-regular);
  color: var(--white);
  display: flex;
  font-family: var(--font-oswald-bold);
  font-size: 1.6rem;
  height: 5.2rem;
  justify-content: center;
  margin-top: 1.4rem;
  text-transform: uppercase;
  width: 8rem;
`;

const MobileMenuSearchButton = styled.button`
  align-items: center;
  border: none;
  background-color: var(--black);
  border-radius: var(--border-regular);
  color: var(--white);
  display: flex;
  font-family: var(--font-oswald-bold);
  font-size: 1.6rem;
  height: 5.2rem;
  justify-content: center;
  margin-top: 1.4rem;
  text-transform: uppercase;
  width: 8rem;
  margin-right: 1rem;
`;

const MobileMenuNav = styled.nav`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    width: 92vw;
    height: 98vh;
    background: var(--black);
    margin: auto;
    border-radius: var(--border-regular);
    list-style: none;
    margin: 0px;
    padding: 4rem 2rem;
    text-align: center;
  }

  li {
    padding-bottom: 2.5rem;
    padding-top: 0.5rem;

    &:last-child {
      margin-top: 4rem;
    }
  }

  a {
    font-size: 2.4rem;
    color: var(--white);
    font-family: var(--font-oswald-bold);
    text-transform: uppercase;
    display: block;

    &.active {
      color: var(--red-light);
      pointer-events: none;
    }
  }
`;

const MobileMenuNavCloseButton = styled.button`
  border: 1px solid var(--white);
  border-radius: var(--border-small);
  background: transparent;
  color: var(--white);
  padding: 1rem 2rem;
  font-family: var(--font-oswald-regular);
  text-transform: uppercase;
  font-size: 1.8rem;
`;

const DesktopMenuWrapper = styled.div`
  margin-top: 1.4rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);

  @media (min-width: 1024px) {
    align-self: flex-start;
    display: flex;
  }
`;

const DesktopMenuNav = styled.nav`
  background-color: var(--white);
  border-top-left-radius: var(--border-regular);
  border-top-right-radius: var(--border-regular);
  margin-bottom: 0.4rem;
  padding-left: 2rem;
  padding-right: 0.8rem;
  transition: background-color 0.4s ease, color 0.4s ease;

  @media (min-width: 768px) {
    &:hover {
      background-color: var(--black);

      li:last-child {
        border-color: var(--white);
      }

      a,
      button {
        color: var(--white);
      }
    }
  }

  @media (min-width: 1024px) {
    border-bottom-left-radius: var(--border-regular);
    border-top-left-radius: var(--border-regular);
    border-top-right-radius: 0px;
    margin-bottom: 0px;
    margin-right: 0.6rem;
  }

  ul {
    align-items: center;
    display: flex;
    font-size: 1.6rem;
    justify-content: center;
    list-style: none;
    padding: 0px;
    margin: 0px;
    text-transform: uppercase;
  }

  li {
    display: flex;

    &:last-child {
      border-left: 1px dashed var(--black);
      padding-left: 0.8rem;
      margin-left: 1rem;

      &:hover {
        button {
          color: var(--yellow-light);
        }
      }
    }
  }

  button {
    border: none;
    background-color: transparent;
    text-transform: uppercase;
  }

  a,
  button {
    color: var(--black);
    font-family: var(--font-oswald-regular);
    overflow: hidden;
    padding-bottom: 1.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    position: relative;
    transition: color 0.4s ease;
    z-index: 5;
    cursor: pointer;

    &::before {
      background-color: var(--red-light);
      border-bottom-left-radius: var(--border-small);
      border-bottom-right-radius: var(--border-small);
      content: "";
      height: 0px;
      left: 0px;
      position: absolute;
      top: 0px;
      transition: height 0.25s ease;
      width: 100%;
    }

    &.active {
      font-family: var(--font-oswald-bold);
      pointer-events: none;

      &::before {
        height: 0.3rem;
      }
    }

    &:hover {
      &::before {
        height: 0.3rem;
      }
    }

    @media (min-width: 768px) {
      &:hover {
        color: var(--yellow-light);
      }
    }
  }
`;

const DesktopMenuFooter = styled.div`
  align-item: center;
  background-color: var(--red-dark);
  border-bottom-left-radius: var(--border-regular);
  border-bottom-right-radius: var(--border-regular);
  font-size: 1.4rem;
  display: flex;
  padding-bottom: 0.8rem;
  padding-left: 2rem;
  padding-right: 2.5rem;
  padding-top: 0.6rem;
  justify-content: flex-end;
  transition: background-color 0.25s ease;

  @media (min-width: 768px) {
    &:hover {
      background-color: var(--black);
    }
  }

  @media (min-width: 1024px) {
    border-bottom-left-radius: 0px;
    border-top-right-radius: var(--border-regular);
    font-size: 1.6rem;
    padding-right: 2rem;
    padding-top: 0px;
    display: flex;
  }

  .menu-footer-desktop-wrapper {
    display: flex;

    @media (min-width: 1024px) {
      flex-direction: column;
      justify-content: center;
      padding-top: 0.2rem;
    }
  }

  .menu-footer-span {
    color: var(--white);
    text-transform: uppercase;
    margin-right: 0.6rem;

    @media (min-width: 1024px) {
      font-family: var(--font-quattrocento-bold);
      font-size: 1.2rem;
      text-transform: none;
    }
  }

  a {
    color: var(--white);
    transition: color 0.4s ease;

    @media (min-width: 768px) {
      &:hover {
        color: var(--yellow-light);
      }
    }
  }

  ul {
    align-items: center;
    display: flex;
    list-style: none;
    padding: 0px;
    margin-bottom: 0px;
    margin-left: 5rem;
    margin-right: 0px;
    margin-top: 0px;

    @media (min-width: 1024px) {
      align-items: flex-end;
      padding-bottom: 0.2rem;
    }
  }

  li {
    display: flex;
    margin-left: 0.6rem;
    margin-right: 0.6rem;

    @media (min-width: 1024px) {
      margin-left: 0.4rem;
      margin-right: 0px;
    }

    > a {
      align-items: center;
      color: var(--white);
      display: flex;
      transition: color 0.4s ease;

      @media (min-width: 1024px) {
        font-size: 2.4rem;

        &:hover {
          color: var(--yellow-light);
        }
      }
    }
  }
`;

const MobileMenu = ({
  isMobileMenuActive,
  setMobileMenuActive,
  setSearchActive,
}) => {
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchActive(true);
  };

  return (
    <span style={{ display: "flex" }}>
      <MobileMenuSearchButton onClick={handleSearchSubmit}>
        search
      </MobileMenuSearchButton>
      <MobileMenuButton
        onClick={() => setMobileMenuActive(!isMobileMenuActive)}
      >
        menu
      </MobileMenuButton>
    </span>
  );
};

const DesktopMenu = ({ setSearchActive }) => {
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchActive(true);
  };
  return (
    <DesktopMenuWrapper>
      <DesktopMenuNav>
        <ul>
          <li>
            <Link to='/' activeClassName='active'>
              home
            </Link>
          </li>
          <li>
            <Link
              to='/our-products'
              activeClassName='active'
              partiallyActive={true}
            >
              our products
            </Link>
          </li>
          <li>
            <Link
              to='/our-brands'
              activeClassName='active'
              partiallyActive={true}
            >
              our brands
            </Link>
          </li>
          <li>
            <Link to='/about-us' activeClassName='active'>
              about us
            </Link>
          </li>
          <li>
            <Link to='/contact-us' activeClassName='active'>
              contact us
            </Link>
          </li>
          <li>
            <button onClick={handleSearchSubmit}>search</button>
          </li>
        </ul>
      </DesktopMenuNav>
      <DesktopMenuFooter>
        <div className='menu-footer-desktop-wrapper'>
          <span className='menu-footer-span'>Call us on</span>{" "}
          <a href='tel:+0394950833'>(03) 9495 0833</a>
        </div>
        <ul>
          <li>
            <a
              href='https://www.facebook.com/shueyshing.au/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <AiFillFacebook />
              <p className='a11y-hide'>shueyshing facebook</p>
            </a>
          </li>
          <li>
            <a
              href='http://www.instagram.com/shueyshing'
              target='_blank'
              rel='noopener noreferrer'
            >
              <AiFillInstagram />
              <p className='a11y-hide'>shueyshing instagram</p>
            </a>
          </li>
        </ul>
      </DesktopMenuFooter>
    </DesktopMenuWrapper>
  );
};

export default function Header() {
  const [isMobileMenuActive, setMobileMenuActive] = useState(false);
  const [isSearchActive, setSearchActive] = useState(false);
  const isMobile = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    if (typeof window === "undefined" || !window.document) return;
    const html = document.querySelector("html");

    isMobileMenuActive || isSearchActive
      ? (html.style.overflow = "hidden")
      : (html.style.overflow = "visible");
  }, [isMobileMenuActive, isSearchActive]);

  const { site } = useStaticQuery(graphql`
    query {
      site: sanitySiteSettings {
        title
        logo {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  `);
  return (
    <>
      <HeaderWrapper>
        {isSearchActive && (
          <SearchProduct onClose={() => setSearchActive(!isSearchActive)} />
        )}
        <LogoWrapper>
          <Link to='/' className='gatsby-image-wrapper'>
            <GatsbyImage
              image={site.logo.asset.gatsbyImageData}
              alt={site.title}
            />
            <p className='a11y-hide'>go to homepage</p>
          </Link>
        </LogoWrapper>
        {isMobile && (
          <MobileMenu
            isMobileMenuActive={isMobileMenuActive}
            setMobileMenuActive={setMobileMenuActive}
            setSearchActive={setSearchActive}
          />
        )}
        {!isMobile && <DesktopMenu setSearchActive={setSearchActive} />}
      </HeaderWrapper>
      {isMobileMenuActive && (
        <MobileMenuNav>
          <ul>
            <li>
              <Link
                to='/'
                activeClassName='active'
                onClick={() => setMobileMenuActive(!isMobileMenuActive)}
              >
                home
              </Link>
            </li>
            <li>
              <Link
                to='/our-products'
                activeClassName='active'
                partiallyActive={true}
                onClick={() => setMobileMenuActive(!isMobileMenuActive)}
              >
                our products
              </Link>
            </li>
            <li>
              <Link
                to='/our-brands'
                activeClassName='active'
                partiallyActive={true}
                onClick={() => setMobileMenuActive(!isMobileMenuActive)}
              >
                our brands
              </Link>
            </li>
            <li>
              <Link
                to='/about-us'
                activeClassName='active'
                onClick={() => setMobileMenuActive(!isMobileMenuActive)}
              >
                about us
              </Link>
            </li>
            <li>
              <Link
                to='/contact-us'
                activeClassName='active'
                onClick={() => setMobileMenuActive(!isMobileMenuActive)}
              >
                contact us
              </Link>
            </li>
            <li>
              <MobileMenuNavCloseButton
                onClick={() => setMobileMenuActive(!isMobileMenuActive)}
              >
                close menu
              </MobileMenuNavCloseButton>
            </li>
          </ul>
        </MobileMenuNav>
      )}
    </>
  );
}
