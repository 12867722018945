import { createGlobalStyle } from "styled-components";

import oswaldBold from "../assets/fonts/oswald-bold.ttf";
import oswaldRegular from "../assets/fonts/oswald-regular.ttf";
import quattroCentoRegular from "../assets/fonts/quattrocento-regular.ttf";
import quattroCentoBold from "../assets/fonts/quattrocento-bold.ttf";

const Typography = createGlobalStyle`
  @font-face {
    font-family: OswaldBold;
    src: url(${oswaldBold});
  }
  @font-face {
    font-family: OswaldRegular;
    src: url(${oswaldRegular});
  }
  @font-face {
    font-family: QuattroCentoRegular;
    src: url(${quattroCentoRegular});
  }
  @font-face {
    font-family: QuattroCentoBold;
    src: url(${quattroCentoBold});
  }

  html {
    font-family: QuattroCentoRegular, serif;
    color: var(--black);
  }

  p {
    font-size: 1.6rem;
    line-height: 1.25;
  }

  p, li {
    letter-spacing: 0.5px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: OswaldBold, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    margin: 0;
    line-height: 1.25;
  }

  a {
    text-decoration: none;
  }
`;

export default Typography;
