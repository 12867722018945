import React from "react";
import styled from "styled-components";
import "normalize.css";
import GlobalStyles from "../styles/GlobalStyles";
import Typography from "../styles/Typography";
import Header from "./Header";
import Footer from "./Footer";

const MainWrapper = styled.main`
  position: relative;
  padding-bottom: 8rem;
  z-index: 0;
`;

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <Header />
      <MainWrapper>{children}</MainWrapper>
      <Footer />
    </>
  );
}
